function numberCellFormatter(params) {
    return (Math.round(params.value * 100) / 100).toString();
}

function diferencaFormatter(params) {
    const valor = (Math.round(params.value * 100) / 100)
    let prefix;
    if (valor > 0) {
        prefix = "+";
    } else  {
        prefix = "";
    }
    return prefix + valor;
}

// /// TODO - Analisar junto ao operador modelo de exibição de tempo sem receber state
// function calcularHorasUltimoStatus(dataStatus) {
//     try {
//         const minutos = Math.floor((Math.abs(new Date() - dataStatus)) / 1000 / 60 );
//         if(minutos < 5){
//             return "online"
//         }else{
//             if(minutos < 60){
//                 return String(minutos) + " min"
//             }else{
//                 if(minutos > 1440){
//                     const dias = Math.round(minutos / 60 / 24)
//                     return String(dias) + " D"
//                 }else{
//                     const horas = Math.round(minutos / 60)
//                     return String(horas) + " h"
//                 }
//             }
//         }
//     } catch (error) {
//         console.error(error);
//         return "erro...";
//     }
// }

function dateTimeCellFormatter(params) {
    return params.value ? params.value.toLocaleDateString("pt-BR") + " " + params.value.toLocaleTimeString("pt-BR"): null;
}

function stateFormatter(params) {
    return "";
}

function getIconeClass(params) {
    if (params.data) {
        // const timeout = params.data.atualizado.getTime() + (15 * 60 * 1000); //15 min
        if (params.data.deviceState === "warning") {
            return "bi-tools text-danger";
        } else if (params.data.deviceState === "offline") {
            return "bi-wifi-off text-danger";
        } else {
            return null;
        }
    }
}

function getDifPercent(params) {
    if (params.data && (params.data.vazao || params.data.diferenca)) {
        if ((params.data.vazao - params.data.diferenca) === 0) {
            return 100;
        } else {
            return Math.abs(params.data.diferenca) / (params.data.vazao - params.data.diferenca) * 100;
        }
    } else {
        return null;
    }     
}

function getOfflineTime(params) {
    if (params.data && params.data.deviceState === "offline") {
        const dt = new Date();
                // Calcula a diferença em milissegundos entre as duas datas
        const diferenca = Math.abs(dt - params.data.deviceStateAt);

        // Calcula os dias, horas e minutos
        const horas = Math.floor(diferenca / (1000 * 60 * 60)).toString().padStart(3, ' ');
        const minutos = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, ' ');

        // Retorna o resultado formatado
        return `${horas} h e ${minutos} min`;
        
    } else {
        return null;
    }
}

const columnDefs = [
    {
        headerName: "Status",
        field: 'deviceState',
        minWidth: 80,
        maxWidth: 80,
        cellClass: getIconeClass,
        valueFormatter: stateFormatter
    },
    {
        field: 'Ciclo filial',
        enableRowGroup: true,
        initialRowGroupIndex: 0,
        hide: true,
        minWidth: 150
    },
    {
        field: 'Projeto de nutrição',
        enableRowGroup: true,
        initialRowGroupIndex: 1,
        minWidth: 200,
        hide: true
    },
    {
        headerName: "Conj. Talhões",
        field: 'Conjunto de talhões',
        enableRowGroup: true,
        minWidth: 150,
        // maxWidth: 150,
        sortable: true
    },
    { 
        headerName: "Talhão (ha, m3/h)",
        field: 'Talhão', 
        minWidth: 180,
        type: "numericColumn",
        sortable: true
    },
    { 
        headerName: 'Vazão(m3/h)', 
        field: 'vazao', 
        type: 'numericColumn', 
        minWidth: 120,
        maxWidth: 120,
        cellRenderer: 'agAnimateShowChangeCellRenderer',
        valueFormatter: numberCellFormatter,
        sortable: true
    },
    { 
        headerName: 'Dif.(m3/h)', 
        field: 'diferenca', 
        type: 'numericColumn', 
        minWidth: 105,
        maxWidth: 105,
        cellRenderer: 'agAnimateShowChangeCellRenderer',
        valueFormatter: diferencaFormatter,
        sortable: true
    },
    { 
        headerName: 'Dif.(%)', 
        field: 'diferencaPercent', 
        type: 'numericColumn', 
        minWidth: 85,
        maxWidth: 85,
        cellRenderer: 'agAnimateShowChangeCellRenderer',
        valueFormatter: numberCellFormatter,
        valueGetter: getDifPercent,
        sortable: true
    },
    {
        headerName: 'Atualizado em',
        field: 'atualizado',
        width: 150,
        minWidth: 150,
        cellRenderer: 'agAnimateShowChangeCellRenderer',
        valueFormatter: dateTimeCellFormatter
    },
    {
        headerName: 'UTAR',
        field: 'deviceId',
        width: 140,
        minWidth: 140,
        cellRenderer: 'agAnimateShowChangeCellRenderer',
        sortable: true
    },
    {
        headerName: 'Versão',
        field: 'version',
        width: 20,
        minWidth: 50,
        cellRenderer: 'agAnimateShowChangeCellRenderer',
        sortable: true
    },
    {
        headerName: 'Inicializações',
        field: 'startupCount',
        width: 20,
        minWidth: 50,
        type: 'numericColumn', 
        cellRenderer: 'agAnimateShowChangeCellRenderer',
        sortable: true
    },
    {
        headerName: 'Vezes offline',
        field: 'offlineCount',
        width: 20,
        minWidth: 50,
        type: 'numericColumn', 
        cellRenderer: 'agAnimateShowChangeCellRenderer',
        sortable: true
    },
    {
        headerName: 'Tempo offline',
        field: 'offlineTime',
        width: 20,
        minWidth: 50,
        cellRenderer: 'agAnimateShowChangeCellRenderer',
        valueGetter: getOfflineTime,
        sortable: true
    },
    { 
        field: 'alerta', 
        width: 80 ,
        cellRenderer: 'agAnimateShowChangeCellRenderer',
        hide: true
    }
];

const classByAlerts = {
    "smaller": "bg-danger text-white",
    "bigger": "bg-warning text-dark",
    "running": "bg-success text-white",
    "off-line": "bg-secondary text-white",
    "out": "bg-primary text-white",
    "default": "bg-light text-dark"
};

function getRowClass(params) {
    let res;
    if (params.data) {
        if (params.data.deviceState !== "running") {
            res = classByAlerts["off-line"];
        } else {
            res = classByAlerts[params.data.alerta];
            if (!res ) {
                const controleSistema = params.data.controleSistema;
                if (controleSistema === 1) {
                    res = classByAlerts.running;
                } else if (controleSistema === 0 && params.data.vazao && Math.round(params.data.vazao * 100) !== 0) {
                    res = classByAlerts.out;
                } else {
                    res = classByAlerts.default;
                }
            }
        }
    }
    return res;
}

export const gridOptions = {
    columnDefs: columnDefs,
    asyncTransactionWaitMillis: 500,
    groupUseEntireRow: true,
    getRowNodeId: function(data) {
        return data.id; //data['Ciclo filial'] + "#" + data['Projeto de nutrição'] + "#" + data['Conjunto de talhões'] + "#" + data['Talhão'];
    },
    groupDefaultExpanded: 1,
    rowGroupPanelShow: 'always',
    suppressMakeColumnVisibleAfterUnGroup: false,
    suppressDragLeaveHidesColumns: false,
    showOpenedGroup: false,
    getRowClass: getRowClass,
    defaultColDef: {    
      width: 120,
      resizable: true,
      flex: 1
    }
  };