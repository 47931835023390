import { ApolloClient, split, HttpLink, InMemoryCache } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

/// TODO - implementar seleção de ENV de Dev quando teste local
// export const HASURA_GRAPHQL_ENGINE_HOSTNAME = '34.139.171.175';
// const wsurl = `ws://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;
// const httpurl = `http://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;

// export const HASURA_GRAPHQL_ENGINE_HOSTNAME = 'atm-hasura.itaueira.com';
export const HASURA_GRAPHQL_ENGINE_HOSTNAME = 'atm-hasura-monitor.itaueira.com';
// export const HASURA_GRAPHQL_ENGINE_HOSTNAME = '34.117.190.91';
const wsurl = `wss://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;
// const wsurl = `ws://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;
const httpurl = `https://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;
// const httpurl = `http://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;

export default function createApolloClient() {
    const wsLink = new WebSocketLink({
        uri: wsurl,
        options: {
            reconnect: true,
            connectionParams: {
                headers: {
                    "x-hasura-admin-secret": "udb_itr_21"
                } 
            }
        }
    });
    
    const httpLink = new HttpLink({
        uri: httpurl,
        headers: {
            "x-hasura-admin-secret": "udb_itr_21"
        } 
    });
    
    const link = split(
        // split based on operation type
        ({ query }) => {
            const { kind, operation } = getMainDefinition(query);
            return kind === 'OperationDefinition' && operation === 'subscription';
        },
        wsLink,
        httpLink
    );

    return new ApolloClient({
        link,
        cache: new InMemoryCache(),
        credentials: "include"
    });
};