import React, {useReducer} from "react";

function createSession(reducer, actions, initialState) {
    const SessionContext = React.createContext();
  
    const SessionContextProvider = ({ children }) => {
        const [session, dispatch] = useReducer(reducer, initialState);
    
          const boundActions = {};
    
          for (let key in actions) {
            boundActions[key] = actions[key](dispatch);
          }
    
        return (
            <SessionContext.Provider value={{ session, ...boundActions }}>
                {children}
            </SessionContext.Provider>
        );
    };
  
    return { SessionContext, SessionContextProvider };
};

function reducer(session, action) {
    switch (action.type) {
        case "login":
            return {...session, userPayload: action.payload};
        case "logout":
            return {...session, userPayload: null};
        case "refresh":
            return {...session, userPayload: action.payload};
        case "setGridApi":
            return {...session, gridApi: action.gridApi};
        case "setGridData":
            return {...session, gridData: action.gridData};
        case "setColumnApi":
            return {...session, columnApi: action.columnApi};
        default:
            return session;
    }
}

const login = dispatch => payload => {
    dispatch({
        type: "login",
        payload
    });
};

const refreshAuth = dispatch => payload => {
    dispatch({
        type: "login",
        payload
    });
};

const logout = dispatch => () => {
    dispatch({
        type: "logout"
    });
}

const setGridApi = dispatch => gridApi => {
    dispatch({
      type: 'setGridApi',
      gridApi
    });
};

const setGridData = dispatch => gridData => {
    dispatch({
      type: 'setGridData',
      gridData
    });
};

const setColumnApi = dispatch => columnApi => {
    dispatch({
      type: 'setColumnApi',
      columnApi
    });
};

const initialSession = {
    userPayload: null,
    gridApi: null,
    columnApi: null,
    gridData: {}
}

export const { SessionContext, SessionContextProvider } = createSession(
    reducer,
    { 
        login,
        refreshAuth,
        logout,
        setGridApi,
        setColumnApi,
        setGridData
    },
    initialSession
  );