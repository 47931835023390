import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {SessionContextProvider} from "./session";
import { ApolloProvider } from '@apollo/client/react';
import createApolloClient from './gql/client';

const client = createApolloClient();

ReactDOM.render(
  <React.StrictMode>
    <SessionContextProvider>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </SessionContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);