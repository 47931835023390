import React from 'react';
import Navbar from 'react-bootstrap/Navbar';

const NavbarMonitor = () => {
    return (
        <Navbar bg="light" variant="light" expand="lg">
            <Navbar.Toggle aria-controls="navbarScroll"/>
            <Navbar.Brand href="#home">UTAR Monitor v2.1.1</Navbar.Brand>
        </Navbar>
    );
};

export default NavbarMonitor;