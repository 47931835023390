import React from 'react';
import NavbarMonitor from './components/NavbarMonitor';
import DataView from "./components/DataView";

export default function App() {
    return (
        <>
            <NavbarMonitor />
            <DataView />
        </>);
};