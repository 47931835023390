import React, {useContext, useEffect, useState} from 'react';
import { useQuery, useSubscription } from '@apollo/client/react';
import { gql } from '@apollo/client';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './DataView.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {SessionContext} from '../session';
import {gridOptions} from '../configs/dataViewOptions';

const MINUTES = 60000;
const monitor = "MML.NTR - Vazão";
const fetchPolicy = "no-cache";
const utarstate = "offline";
const gqlParams = { variables: {  monitor }, fetchPolicy, };

const SUB_MONITOR = gql`subscription getData($dateFilter: timestamptz!, $dateOfflineFilter: timestamptz!, $utarstate: String!) {
    data(
        where: {
                _or:[
                    {
                        value_name: {_in: ["vazao", "controleSistema"]},
                        value_collected_at: {_gt: $dateFilter }
                    },
                    {
                        value_name: {_in: ["vazao", "controleSistema"]},
                        device_state_at: {_gt: $dateOfflineFilter },
                        device_state: {_eq: $utarstate }
                    },
                    {
                        value_name: {_in: ["vazao", "controleSistema"]},
                        device_state_at: {_gt: $dateFilter }
                    }
                ]
    }) {
        alert_type
        identification {
            name
            value
            order
        }
        description {
          value_label
          order
          visible
          value_reference_target
          value_reference_min
          value_reference_max
          value_unitMeasurement
        }
        alert_occurrence_at
        alert_out_of_bounds
        value_name
        value_value
        value_collected_at
        device_state
        device_state_at
        device_state_info
        device_id
        startup_count
        offline_count
    }
}`;

const QUERY_MONITOR = gql`query getData($monitor: String!) {
            data(
                where: {
                    identification: {
                        monitor_id: {_eq: $monitor}
                    },
                    value_name: {_in: ["vazao", "controleSistema"]}
            }) {
                alert_type
                identification {
                    name
                    value
                    order
                }
                description {
                  value_label
                  order
                  visible
                  value_reference_target
                  value_reference_min
                  value_reference_max
                  value_unitMeasurement
                }
                alert_occurrence_at
                alert_out_of_bounds
                value_name
                value_value
                value_collected_at
                device_state
                device_state_at
                device_state_info
                device_id
                startup_count
                offline_count
            }
        }`

function compareId(a, b) {
    if (a.id < b.id) {
        return -1;
    } else if (a.id > b.id) {
        return 1;
    } else {
        return 0;
    }
}

function compareDataIdentification(a, b) {
    if (a.order < b.order) {
        return -1;
    } else if (a.order > b.order) {
        return 1;
    } else {
        return 0;
    }
}

function transformData(rawData) {
    const aux = {};
    const auxCollectedAt = {};
    const rowData = [];
    for (let i = 0; i < rawData.length; i++) {
        const data = rawData[i];
        data.identification.sort(compareDataIdentification);
        const id = [];
        let algumaIdentificacaoComValor = false;
        data.identification.forEach((ident) => {
            if(ident.name == "Talhão"){
                id.push(ident.value);
                algumaIdentificacaoComValor = algumaIdentificacaoComValor || (!!ident.value && ident.value !== "000 (00.00 ha, 00,00 m3/h)");
            }
        });
        if (algumaIdentificacaoComValor) {
            let obj = aux[id.join("#")];
            let lastCollectedAt = auxCollectedAt[id.join("#") + "#" + data.value_name];

            const value_collected_at = new Date(data.value_collected_at);
            const device_state_at = new Date(data.device_state_at);

            if (obj) {
                if (data.description.length) {
                    obj.references[data.value_name] = {
                        target: data.description[0].value_reference_target,
                        min: data.description[0].value_reference_min,
                        max: data.description[0].value_reference_max
                    };    
                }
                if (obj[data.value_name] === undefined || lastCollectedAt === undefined || lastCollectedAt < value_collected_at) {
                    obj[data.value_name] = data.value_value;
                    auxCollectedAt[id.join("#") + "#" + data.value_name] = value_collected_at;
                    if (obj.atualizado < value_collected_at) {
                        obj.atualizado = value_collected_at;
                    }
                }
                if (obj.deviceStateAt < device_state_at) {
                    obj.deviceStateAt = device_state_at;
                    obj.deviceState = data.device_state;
                    transformDeviceInfo(obj,data);
                }
                obj.startupCount = data.startup_count;
                obj.offlineCount = data.offline_count;
            } else {
                obj = {
                    references: {}
                };
                aux[id.join("#")] = obj;
                data.identification.forEach((ident) => {
                    obj[ident.name] = ident.value;
                });
                obj.id = id.join("#");
                obj[data.value_name] = data.value_value;
                if (data.description.length) {
                    obj.references[data.value_name] = {
                        target: data.description[0].value_reference_target,
                        min: data.description[0].value_reference_min,
                        max: data.description[0].value_reference_max
                    };    
                } else {
                    obj.references[data.value_name] = {
                        target: null,
                        min: null,
                        max: null
                    };
                }
                obj.atualizado = value_collected_at;
                obj.diferenca = data.alert_out_of_bounds;
                obj.deviceStateAt = device_state_at;
                obj.deviceState = data.device_state;
                transformDeviceInfo(obj,data);
                obj.startupCount = data.startup_count;
                obj.offlineCount = data.offline_count;

                auxCollectedAt[id.join("#") + "#" + data.value_name] = value_collected_at;

                rowData.push(obj);
            } 
        }       
    };
    rowData.sort(compareId);
    return rowData;
}

function transformDeviceInfo(obj,data) {
    if(data?.device_id){
        obj.deviceId = data.device_id;
    }
    if(data?.device_state_info){
        obj.deviceStateInfo = data.device_state_info;                
        const deviceStateInfo = JSON.parse(obj.deviceStateInfo);
        if(deviceStateInfo && deviceStateInfo.version){
            obj.version = deviceStateInfo.version;
        }else{
            obj.version = "-.-";
        }
    }
}

function setAlerts(data) {
    const controleSistema = data.controleSistema;
    if (controleSistema === 1 && data.references.vazao) {
        if (data.vazao > data.references.vazao.max) {
            data.alerta = "bigger";
        } else if (data.vazao < data.references.vazao.min) {
            data.alerta = "smaller";
        } else {
            data.alerta = null;
        }
        data.diferenca = data.vazao - data.references.vazao.target;
    } else if (controleSistema === 0 && data.vazao) {
        data.alerta = "out";
        data.diferenca = data.vazao;
    } else {
        data.alerta = null;
        data.diferenca = 0;
    }
}

function DataView() {

    const [gqlParamsSub, setGqlParamsSub] = useState({ 
        variables: { 
            dateFilter: new Date(new Date().getTime()-(MINUTES/2)),
            dateOfflineFilter:  new Date(new Date().getTime()-(MINUTES*6)),
            utarstate
        }, 
        fetchPolicy, 
    });

    const queryInit = useQuery(QUERY_MONITOR, gqlParams);
    const { data, error, loading, variables} = useSubscription(SUB_MONITOR, gqlParamsSub);
    const [gridInit, setGridInit] = useState(false);
    const [delayDraw, setDelayDraw] = useState(Date.now());
    const [subPerMinute, setSubPerMinute] = useState(1);
    const [currentMintute, setCurrentMintute] = useState(1);
    const [countMintute, setCountMintute] = useState(0);
    const [updateDateFilter, setUpdateDateFilter] = useState(false);

    const { session: { gridApi, columnApi, gridData }, setGridApi, setColumnApi, setGridData } = useContext(SessionContext);
    
    gridOptions.onGridReady = (params) => {
        if (gridApi === null) {
            setGridApi(params.api);
        }
        if (columnApi === null) {
            setColumnApi(params.columnApi);
        }
    };

    const applyGridData = (data, query) => {
        const rows = transformData(data);
        const localData = gridData;
        for(const line of rows){
            if(line['Talhão']){
                if(localData[line['Talhão']]){
                    for(const item in line){
                        if(item != "references"){
                            localData[line['Talhão']][item] = line[item];
                        }else{
                            for(const itemRef in line['references']){
                                localData[line['Talhão']]['references'][itemRef] = line['references'][itemRef];
                            }
                        }
                    }
                }else{
                    localData[line['Talhão']] = line;
                }
            }
            setAlerts(localData[line['Talhão']]);
        }
        
        setGridData(localData);

        const listData = [];
        for(const line in localData){
            listData.push(localData[line]);
        }
        // console.log("localData: ", localData);
        if (gridApi) {
            if(query){
                gridApi.setRowData(listData);
            }else{
                gridApi.applyTransactionAsync({ update: listData });
                if(delayDraw < Date.now() ){
                    setDelayDraw(Date.now()+2000);
                    if(listData.length > 0){
                        setTimeout(() => {
                                gridApi.redrawRows();
                                setGridApi(gridApi);
                        }, "1800");
                    }
                }
            }
            setGridApi(gridApi);
        }
        if (columnApi && query) {
            const allColumnIds = [];
            columnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.colId);
            });
            columnApi.autoSizeColumns(allColumnIds, false);
            setColumnApi(columnApi);
        }
    }

    const updateCount = () => {
        if(currentMintute != new Date().getMinutes()){
            setSubPerMinute(countMintute);
            setCurrentMintute(new Date().getMinutes());
            setCountMintute(1);
            setUpdateDateFilter(true);
        }else{
            setCountMintute(countMintute+1);
        }
        console.log("subPerMinute/currentMintute/countMintute:", { subPerMinute, currentMintute, countMintute });
    }

    
    const updateGqlFilter = () => {
        if(updateDateFilter && subPerMinute > 0){
            // const dateFilter = new Date(new Date().getTime() - (MINUTES*3) );
            const dateFilter = new Date(new Date().getTime() - Math.round( (MINUTES/2) / subPerMinute ));
            const dateOfflineFilter = new Date(new Date().getTime() - (MINUTES*6) ); 
            console.log("################################" );
            console.log("Range date filter:", Math.round( ((MINUTES/2) / subPerMinute)/1000 ));
            console.log("################################" );
            setUpdateDateFilter(false);
            setGqlParamsSub({ 
                variables: { 
                    dateFilter,
                    dateOfflineFilter,
                    utarstate
                }, 
                fetchPolicy, 
            });
        }
    }
    
    useEffect(() => {
        if(error){
            console.log("Hasura Subscription info:", { error, loading, variables });
        }
        if(queryInit.data && !gridInit){
            applyGridData(queryInit.data.data, true);
            setGridInit(true);
        }
        if(data){
            console.log("dateFilter: ", gqlParamsSub.variables.dateFilter );
            console.log("new sub list: ", data.data);
            applyGridData(data.data, false);
        }
        updateCount();
        updateGqlFilter();
    }, [data, error]);

    return (
        <div style={{width: '100%', height: 'calc(100vh - 70px)'}}>
            <div className="ag-theme-balham" style={{width: "100%", height: "100%"}}>
                <AgGridReact gridOptions={gridOptions} />
            </div>
        </div>
        );
}

export default React.memo(DataView);